<template>
  <div class="app-container">
    <eHeader
      :show-status="showStatus"
      @search-click="searchHandle"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="名字/公司名"/>
      <el-table-column prop="phone" label="电话">
       </el-table-column>
      <el-table-column prop="email" label="邮箱">

      </el-table-column>
      <el-table-column label="产品">
        <template slot-scope="scope">
          <span>{{ getProductNane(scope.row.productId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="infoContent" label="信息">
      </el-table-column>
      <el-table-column prop="applyDate" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ getStatusText(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click.native="doHandle(scope.row)"
          >操作</el-button>

          <!-- <edit class="my-button" v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"/> -->
          <!--<check class="my-button" v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT'])&&visCheck" :data="scope.row" :sup_this="sup_this" :isCheck="true"/>-->
          <!--<check class="my-button" v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_VIEW'])" :data="scope.row" :sup_this="sup_this" :isCheck="false"/>-->
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      :current-page="currentPage"
      @size-change="sizeChange"
      @current-change="pageChange"
      :page-size.sync="size"
      />
    <detai-dialog
      ref="detaidialog"
      :dialog-show="dialogShow"
      :product-info="productInfo"
        @close-dialog="closeHandel"
      @refush-data="refushData"
    ></detai-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
// import check from '@/components/ib/merchantinfo/view'
import { getContactList } from '@/api/alitatech/contactUs.js'
import detaiDialog from './form'
import eHeader from './header'
export default {
  props:{
    showStatus:{
      type: Boolean,
      default: false
    },
    dataStatus:{

    }
  },
  components: {
    detaiDialog,
    eHeader,
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      loading: false,
      dialogShow: false,
      data: [],
      total: 1,
      listQuery: {
        phone: '',
        status: ''
      },
      dialogTitle: 'look',
      productInfo: {}
    }
  },
  watch:{
  },
  created() {
    this.getList();
  },
  methods: {
    parseTime,
    checkPermission,
    doHandle(row){
      this.productInfo = row;
      this.dialogShow = true;
    },
    refushData(){
      this.getList();
    },
    closeHandel(){
      this.dialogShow = false;
    },
    getStatusText(status){
      let text = ''
      switch (status) {
        case 1:
          text = '待分配处理人'
          break;
        case 2:
          text = '处理中'
          break;
        case 3:
          text = '处理中'
          break;
        case 4:
          text = '已完结'
          break;
      }
      return text;
    },
    getProductNane(productId){
      let text = '';
      switch (productId) {
        case 1:
          text = '交易社区（标准版）'
          break;
        case 2:
          text = '交易社区（跟单版）'
          break;
        case 3:
          text = '跨平台API服务'
          break;
        case 4:
          text = '多账户管理系统MAM '
          break;
        case 5:
          text = '客户管理系统CRM '
          break;
        case 6:
          text = '客户管理系统CRM'
          break;
      }
      return text;
    },
    searchHandle(query){
      this.listQuery = query;
      this.getList();
    },
    getList(){
      let params = {
        page: this.currentPage - 1,
        size: this.size
      }
      if(this.listQuery.phone){
        params.phone = this.listQuery.phone
      }else {
        delete params.phone;
      }
      if(this.listQuery.status){
        params.status = this.listQuery.status
      }else {
        delete params.status;
      }
      getContactList(params).then((res) => {
        this.data = res.content;
        this.total = res.totalElements;
      })
    },
    pageChange(e) {
      this.currentPage = e;
      this.getList();
    },
    sizeChange(e) {
      this.currentPage = 1;
      this.size = e
      this.getList();
    }
  }
}
</script>

<style scoped>

</style>
