import request from '@/utils/request'

export function getContactList(data) {
  return request({
    url: '/user/crm/getContactInfo',
    method: 'get',
    params: data
  })
}
/*处理人列表*/
export function getHandleList(data) {
  return request({
    url: '/user/crm/users',
    method: 'get',
    params: data,
  })
}
/*/api/backplat/updateStatus   POST

* @param id id
* @param userId 管理员id 就是User id
* @param content 处理标注/内容
* @param result 处理结果
* @param isBuild 0.不建站; 1.确认建站

* @param status 状态 传入 1    分配处理人   需要传入userId 。                                状态进入status=2
传入 2.   处理人进行处理，可传入 content处理标注/内容。      状态进入status=3
传入 3    处理中，修改content标注/内容。                              不改变状态
传入 4    处理完结，需要传入result 处理结果，和isBuild=0不建站，isBuild=1建站。  状态进入status=4*/

export function handleContactUsSattus(data) {
  return request({
    url: '/user/crm/updateContactInfoStatus',
    method: 'POST',
    data,
  })
}
