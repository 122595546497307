<template>
  <div>
    <tableView />
  </div>
</template>

<script>
  import tableView from '@/components/alitatech/contactUs/table'
  export default {
    name: "contactUs",
    components:{
      tableView,
    }
  }
</script>

<style scoped>

</style>
