<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
      v-model="query.phone"
      clearable
      placeholder="手机号搜索"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @clear="toQuery"
    />
    <!--<el-input-->
      <!--v-model="query.username"-->
      <!--clearable placeholder="姓名搜索"-->
      <!--style="width: 200px;"-->
      <!--class="filter-item"-->
      <!--@keyup.enter.native="toQuery"-->
    <!--/>-->

    <el-select
      v-model="query.status"
      clearable placeholder="请选择状态"
      class="filter-item"
               style="width: 150px"
    >

      <el-option
        v-for="item in siteStatuss"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <!--<div class="filter-item">-->
      <!--申请时间：-->
      <!--<el-date-picker-->
        <!--style="width: 150px;"-->
        <!--v-model="query.startTime"-->
        <!--type="date"-->
        <!--placeholder="开始日期">-->
      <!--</el-date-picker>-->
      <!--至-->
      <!--<el-date-picker-->
        <!--style="width: 150px;"-->
        <!--v-model="query.endTime"-->
        <!--type="date"-->
        <!--placeholder="结束日期">-->
      <!--</el-date-picker>-->
    <!--</div>-->

    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <!-- <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true"/>
    </div> -->
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission' // 权限判断函数
  import eForm from './form'
  import {mapGetters} from 'vuex'

  export default {
    components: {eForm},
    props: {
      showStatus: {
        type: Boolean,
        default: false
      }
      // query: {
      //   type: Object,
      //   required: true
      // },
    },
    data() {
      return {
        query:{
          phone: '',
          status: '',
          startTime: '',
          endTime: ''
        },
        siteStatuss: [
          {
            label: '全部',
            value: 0
          },
          {
            label: '待分配处理人',
            value: 1,
          },
          {
            label: '已分配处理人 ',
            value: 2,
          },
          {
            label: '处理中 ',
            value: 3,
          },
          {
            label: '已完结',
            value: 4
          },
        ]
      }
    },
    computed: {
      ...mapGetters([
        'ibTypeOptions',
        'ibRankOptions'
      ])
    },
    methods: {
      checkPermission,
      toQuery() {
        this.$emit('search-click', this.query);
      }
    }
  }
</script>
