<template>
  <el-dialog
    class="contact-dx-dialog"
    :append-to-body="true"
    :visible="dialogShow"
    width="800px"
    :before-close="handleClose"
    center
  >

    <div class="apply-content">
      状态: <span>{{getStatusText(productInfo.status)}}</span>
    </div>
    <div class="ele-handle-outer" v-if="productInfo.status == 1">
      <div class="ele-handle-text">请选择处理人</div>
      <div>
        <el-select v-model="handleUserId" placeholder="请选择">
          <el-option
            v-for="(item, index) in handlerList"
            :key="index"
            :label="item.username"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <el-row class="remark-row" v-if="productInfo.status == 2 || productInfo.status == 3">
      <el-col>
        <div class="remark-box">
          <span class="remark-span">备注</span>
          <el-input
            type="textarea"
            class="textarea-ele"
            v-model="remark"
            placeholder="请输入备注"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <div class="do-list">
      <span class="remark-span">操作</span>
      <el-button
        v-if="productInfo.status == 1"
        type="primary"
        @click="doSubmit(1)"
      >分配
      </el-button>
      <el-button
        v-if="productInfo.status == 2 "
        type="primary" @click="doSubmit(2)"
      >保存
      </el-button>
      <el-button
        v-if="productInfo.status == 3 "
        type="primary" @click="doSubmit(3)"
      >保存
      </el-button>
      <div
        class="over-box"
        v-if="productInfo.status == 3 "
      >
        <el-radio v-model="isBuild" :label="0">不建站</el-radio>
        <el-radio v-model="isBuild" :label="1">建站</el-radio>
        <el-button
          type="primary" @click="doSubmit(4)"
        >完结
        </el-button>
      </div>
    </div>
    <!--<div slot="footer" class="dialog-footer">-->
    <!--<el-button type="text" @click="cancel">取消</el-button>-->
    <!--<el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>-->
    <!--</div>-->

  </el-dialog>
</template>

<script>
  import {initData} from '@/api/data'
  import {getHandleList, handleContactUsSattus} from '@/api/alitatech/contactUs.js'
  import {mapGetters} from 'vuex'

  export default {
    props: {
      dialogShow: {
        type: Boolean,
        default: false
      },
      productInfo: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        handler: '',
        remark: '', // 备注信息,
        isBuild: 0,
        handleUserId: '',
        handlerList: []
      }
    },
    computed: {
      ...mapGetters([
        'ibTypeOptions'
      ])
    },
    created() {
      this.getList();
    },
    methods: {
      cancel() {
        this.$emit('close-dialog');
      },
      getList() {
        getHandleList({
          size: 100,
        }).then((res) => {
          this.handlerList = res.content;
        })
      },
      initOptions() {
        var url = 'community/crm/tBusinessSector'
        var params = ''
        initData(url, params).then(res => {
          this.sectorOptions = res.content
        }).catch(err => {
          console.log(err.response.data.message)
        })
        url = 'community/crm/tMerchant'
        initData(url, params).then(res => {
          this.merOptions = res.content
        }).catch(err => {
          console.log(err.response.data.message)
        })
      },
      handleClose() {
        this.$emit('close-dialog')
      },
      getStatusText(status) {
        let text = ''
        switch (status) {
          case 1:
            text = '待分配处理人'
            break;
          case 2:
            text = '处理中'
            break;
          case 3:
            text = '处理中'
            break;
          case 4:
            text = '已完结'
            break;
        }
        return text;
      },
      doSubmit(number) {
        switch (number) {
          case 1:
            handleContactUsSattus({
              id: this.productInfo.id,
              status: number,
              userId: this.handleUserId,
            }).then((res) => {
                this.$message({
                  message: '分配成功',
                  type: 'success',
                })
                this.$emit('refush-data');
                this.$emit('close-dialog');
            })
            break;
          case 2:
            handleContactUsSattus({
              status: number,
              id: this.productInfo.id,
              content: this.remark
            }).then((res) => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.$emit('refush-data');
                this.$emit('close-dialog');
            })
            break;
          case 3:
            handleContactUsSattus({
              id: this.productInfo.id,
              status: number,
              content: this.remark
            }).then((res) => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.$emit('refush-data');
                this.$emit('close-dialog');
            })
            break;
          case 4:
            handleContactUsSattus({
              id: this.productInfo.id,
              status: number,
              isBuild: this.isBuild,
              result: this.remark
            }).then((res) => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.$emit('refush-data');
                this.$emit('close-dialog');
            })
            break;
        }
      },
    }
  }
</script>
<style>
  .contact-dx-dialog .el-dialog__body {
    padding-left: 40px;
    padding-right: 20px;
  }
</style>
<style scoped lang="less">
  .do-list,
  .remark-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
  }
  .over-box {
    margin-left: 20px;
  }
  .remark-span {
    min-width: 70px;
  }

  .ele-handle-text {
    margin: 20px 0;
  }

  .radio-ele {
    margin-bottom: 10px;
  }

  .apply-detail {
    .remark-row {
      margin-top: 20px;
    }
    .input-ele {
      width: 200px;
      margin-left: 20px;
    }
  }
</style>
